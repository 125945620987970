
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import DeviceIcon, { Variant } from './DeviceIcon.vue'
import { doutformat } from '@/services/functions'
import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'

const { SWITCH, SWITCH_OLED } = constants.deviceVariants

@Component({
  components: {
    DeviceIcon
  }
})
export default class DeviceOption extends Vue {
  @Prop({ default: SWITCH })
  variant!: Variant

  @Prop()
  policyModel!: SwitchPolicyModel

  @Prop()
  claimInfo!: ClaimInfo | undefined

  @Prop({ default: false })
  selectable!: boolean

  @Prop({ default: false })
  value!: boolean

  @Prop({ default: true })
  showDeviceDesc!: boolean

  get selectedValue() {
    return this.value
  }

  set selectedValue(value: boolean) {
    this.$emit('onSelect', value)
  }

  get isSwitch() {
    return this.variant === SWITCH
  }

  get isPolicyActive(): boolean {
    if (!this.policyModel) {
      // Just displaying device types
      return true
    }
    return (
      this.policyModel
        ? ['IN_FORCE', 'EXPIRING', 'LAPSING'].includes(this.policyModel._policy.status)
        : false)
  }

  get isPolicyExpired() {
    return this.policyModel && this.policyModel.expired
  }

  get originalStartDateTime() {
    if (this.policyModel) {
      return doutformat(this.policyModel.originalStartDateTime)
    } else {
      return null
    }
  }

  get repairEligibilityStartDate() {
    if (this.policyModel) {
      return doutformat(this.policyModel.repairEligibilityStartDate)
    } else {
      return null
    }
  }

  showPolicyStatus(): boolean {
    return ['IN_FORCE', 'LAPSING', 'LAPSED', 'AUTO_LAPSED', 'EXPIRING', 'EXPIRED'].includes(this.policyModel._policy.status)
  }

  translatePolicyStatus() {
    let status = this.policyModel._policy.status

    if (this.policyModel.inStandDown) {
      status = status === 'EXPIRING' ? 'STAND_DOWN_EXPIRING' : 'STAND_DOWN'
    }

    return this.$t(`deviceOption.mapping.${status}`)
  }

  translateDeviceDescription() {
    switch (this.variant) {
      case SWITCH:
        return this.$t('deviceOption.coverage.switch')
      case SWITCH_OLED:
        return this.$t('deviceOption.coverage.switchOLED')
      default:
        return this.$t('deviceOption.coverage.switchLite')
    }
  }

  selectAction() {
    if (!this.selectable) {
      return
    }

    this.selectedValue = !this.selectedValue
  }
}

export interface ClaimInfo {
  deviceName: string;
  claimNumber: number;
}
