















import { Component, Prop, Vue } from 'vue-property-decorator'
import constants from '@/constants'

const { SWITCH, SWITCH_LITE } = constants.deviceVariants

export type Variant = 'switch' | 'switcholed' | 'switchlite'

@Component
export default class DeviceIcon extends Vue {
  @Prop({ default: SWITCH })
  variant!: Variant

  @Prop({ default: false })
  expired!: boolean

  @Prop({ default: true })
  showDeviceDesc!: boolean

  get isSwitchLite() {
    return this.variant === SWITCH_LITE
  }
}
