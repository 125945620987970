
























import { Component, Vue, Prop } from 'vue-property-decorator'

interface ObjectValue {
  imgUrl: string;
  objLabel: string;
}

@Component
export default class DetailField extends Vue {
  @Prop({ default: '' })
  label!: string

  @Prop({ default: '' })
  value!: string

  @Prop({ default: '' })
  valueColor!: string

  @Prop()
  objectValue!: ObjectValue | undefined
}
